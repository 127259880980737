import React from 'react';
import RenderMenu from './RenderMenu';
const SalesFormSuccess = () => {
  return (
    <>
    <RenderMenu />
    <div style={{ padding: "20px", textAlign: "center", backgroundColor: "#f0f0f0", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
      <h1>Thank You!</h1>
      <p>We have received your request.</p>
      <p>Our team will be in touch with you shortly.</p>
    </div>
    </> 
  );
 
};

export default SalesFormSuccess;
