import axios from 'axios';

const API_URL = 'https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/support';
const API_KEY = process.env.REACT_APP_X_API_KEY;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'x-api-key': API_KEY,
    'Content-Type': 'application/json',
  },
});

export const getSignedUrlForObject = async (fileName) => {
  try {
    const response = await axiosInstance.post('/get-signed-urls', {
      attachments: [{ file_name: fileName }],
    });
    return response.data[0].url;
  } catch (error) {
    console.error('Error fetching signed URL:', error.response ? error.response.data : error.message);
    throw new Error('Error fetching signed URL:', error);
  }
};

export const fetchSupportRequests = async () => {
  try {
    const response = await axiosInstance.get('/requests');
    return response.data;
  } catch (error) {
    console.error('Error fetching support requests:', error.response ? error.response.data : error.message);
    throw new Error('Error fetching support requests:', error);
  }
};

export const createSupportRequest = async (requestData) => {
  try {
    console.log('requestData:', requestData);
    const response = await axiosInstance.post('/requests', requestData);
    return response.data;
  } catch (error) {
    console.error('Error creating support request:', error.response ? error.response.data : error.message);
    throw new Error('Error creating support request:', error);
  }
};

export const fetchComments = async (caseId) => {
  try {
    const response = await axiosInstance.get(`/requests/${caseId}/getcomments`);
    return response.data;
  } catch (error) {
    console.error('Error fetching comments:', error.response ? error.response.data : error.message);
    throw new Error('Error fetching comments:', error);
  }
};

export const updateSupportRequest = async (requestId, updateData) => {
  try {
    const response = await axiosInstance.put(`/requests/${requestId}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating support request:', error.response ? error.response.data : error.message);
    throw new Error('Error updating support request:', error);
  }
};

export const deleteSupportRequest = async (requestId) => {
  try {
    const response = await axiosInstance.delete(`/requests/${requestId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting support request:', error.response ? error.response.data : error.message);
    throw new Error('Error deleting support request:', error);
  }
};


export const fetchCaseDetails = async (caseId) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/requests/${caseId}`);
    const data = JSON.parse(response.data.body); // Parse the JSON string
    return data;
  } catch (error) {
    console.error('Error fetching case details:', error.response ? error.response.data : error.message);
    throw new Error('Error fetching case details:', error);
  }
};

export const updateCaseStatus = async (caseId, newStatus) => {
  try {
    const response = await axiosInstance.post(`/requests/${caseId}/updatestatus`, { status: newStatus });
    return response.data;
  } catch (error) {
    console.error('Error updating case status:', error.response ? error.response.data : error.message);
    throw new Error('Error updating case status');
  }
};

export const fetchSignedUrls = async (attachments) => {
  try {
    const response = await axiosInstance.post('/get-signed-urls', { attachments });
    console.log('Attachments:', attachments);
    // Parse the response body to extract the signed URLs
    return JSON.parse(response.data.body);
  } catch (error) {
    console.error('Error fetching signed URLs:', error.response ? error.response.data : error.message);
    throw new Error('Error fetching signed URLs:', error);
  }
};

export const addComment = async (caseId, comment, commenter) => {
  try {
    const response = await axiosInstance.post(`/requests/${caseId}/addcomment`, {
      comment,
      commenter
    });
    return response.data;
  } catch (error) {
    console.error('Error adding comment:', error.response ? error.response.data : error.message);
    throw new Error('Error adding comment:', error);
  }
};