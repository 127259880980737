import React from 'react';

const CommentsList = ({ comments = [] }) => {
  // Sort comments by CreateDate in ascending order
  const sortedComments = comments.slice().sort((a, b) => a.CreateDate - b.CreateDate);

  return (
    <div className="border p-4 rounded-md mb-4 text-left">
      {sortedComments.map((comment, index) => (
        <div key={index} className="mb-2">
          <p className="font-bold">{comment.Author}</p>
          <p>{new Date(comment.CreateDate * 1000).toLocaleString()}</p>
          <p>{comment.Comment}</p>
        </div>
      ))}
    </div>
  );
};

export default CommentsList;