// src/feature-request/FeatureRequestSuccess.js
import React from 'react';

function FeatureRequestSuccess() {
    return (
      <div>
        <h2>Feature Request Submitted</h2>
        <p>Your feature request has been successfully submitted. We will get back to you shortly.</p>
      </div>
    );
}

export default FeatureRequestSuccess;
