import React, { useState } from 'react';
import { getSignedUrlForObject } from './aws-config';

const FileUploadForm = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFileInput = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => file.size <= 100 * 1024 * 1024); // 100MB limit

    if (files.length !== validFiles.length) {
      alert('Some files are over the 100MB limit and will not be uploaded.');
    }

    const initialProgress = validFiles.reduce((acc, file) => {
      acc[file.name] = 0;
      return acc;
    }, {});

    setSelectedFiles(validFiles);
    setProgress(initialProgress);
    setUploadSuccess(false); // Reset upload success
  };

  const uploadFiles = async (files) => {
    let filesUploaded = 0;

    for (const file of files) {
      const fileName = `${file.name}`; // Prefix file name with case ID if available
      const url = await getSignedUrlForObject(fileName, 'putObject');

      try {
        await uploadFile(file, url);
        filesUploaded += 1;

        // Show success message if all files are uploaded
        if (filesUploaded === files.length) {
          setUploadSuccess(true);
          // Optionally redirect to success page
          // navigate('/success');
        }
      } catch (err) {
        console.error('Upload error:', err);
      }
    }
  };

  const uploadFile = (file, url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setProgress(prevProgress => ({
            ...prevProgress,
            [file.name]: percentCompleted,
          }));
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject(new Error(`Upload failed: ${xhr.statusText}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Upload error'));
      };

      xhr.send(file);
    });
  };

  return (
    <div className="bg-customDarkSlateGray min-h-screen flex flex-col items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Upload a File</h2>
        <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700">
          Upload a file
        </label>
        <input
          id="file-upload"
          type="file"
          multiple
          onChange={handleFileInput}
          className="block w-full text-sm text-gray-900 border rounded-lg cursor-pointer focus:outline-none"
        />
        <button
          onClick={() => uploadFiles(selectedFiles)}
          className="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Upload
        </button>
        <div className="mt-4">
          {selectedFiles.map(file => (
            <div key={file.name} className="mb-2">
              <div className="flex justify-between items-center">
                <span className="text-sm">{file.name}</span>
                <span className="text-sm">{progress[file.name]}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${progress[file.name]}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
        {uploadSuccess && (
          <div className="mt-4 text-green-600 font-bold">
            All files have been uploaded successfully!
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploadForm;
