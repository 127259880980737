import React from 'react';
import { useNavigate } from 'react-router-dom';

function UploadSuccess() {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate('/');  // Navigates back to the homepage or change it to your desired route
  };

  return (
    <div className="bg-customDarkSlateGray min-h-screen flex flex-col items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center">
        <h2 className="text-2xl font-bold text-green-600 mb-4">Success!</h2>
        <p className="mb-6">Your file has been successfully uploaded.</p>
        <button
          onClick={handleReturn}
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
        >
          Return to Home
        </button>
      </div>
    </div>
  );
}

export default UploadSuccess;
