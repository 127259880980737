import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import { S3_BUCKET } from '../aws-config';
import './custom.css';

const FeatureRequestForm = ({ user, userData }) => {
  const navigate = useNavigate();
  const MAX_LENGTH = 10000;

  const [title, setTitle] = useState('');
  const [dateOfRequest, setDateOfRequest] = useState(new Date().toLocaleDateString());
  const [productName, setProductName] = useState(''); 
  const [description, setDescription] = useState('');
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [workflow, setWorkflow] = useState('');
  const [integration, setIntegration] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [completionDate, setCompletionDate] = useState('');
  const [comments, setComments] = useState('');
  const [productType, setProductType] = useState('existing');
  const [requesterName, setRequesterName] = useState('');
  const [requesterEmail, setRequesterEmail] = useState('');

  const [showDescriptionCount, setShowDescriptionCount] = useState(false);
  const [showInputCount, setShowInputCount] = useState(false);
  const [showOutputCount, setShowOutputCount] = useState(false);
  const [showWorkflowCount, setShowWorkflowCount] = useState(false);
  const [showIntegrationCount, setShowIntegrationCount] = useState(false);
  const [showCommentsCount, setShowCommentsCount] = useState(false);

  const [progress, setProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  // Predefined valid product names
  const validProductNames = [
    'Support Module',
    'Management Tools',
    'Call Compliance',
    'Analytics Dashboard',
    'Feature Request'
  ];

  useEffect(() => {
    if (userData) {
      setRequesterName(userData.Name || '');
      setRequesterEmail(userData.Email || '');
    }
  }, [userData]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => file.size <= 100 * 1024 * 1024);

    if (files.length !== validFiles.length) {
      alert('Some files are over the 100MB limit and will not be uploaded.');
    }

    const initialProgress = validFiles.reduce((acc, file) => {
      acc[file.name] = 0;
      return acc;
    }, {});

    setAttachments(validFiles);
    setProgress(initialProgress);
  };

  const uploadFiles = async (files) => {
    const s3 = new AWS.S3();
    const uploadPromises = files.map(file => {
      const fileName = `${requesterEmail}/${file.name}`;
      const params = {
        Bucket: S3_BUCKET,
        Key: fileName,
        Body: file,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params).on('httpUploadProgress', (evt) => {
          setProgress(prevProgress => ({
            ...prevProgress,
            [file.name]: Math.round((evt.loaded / evt.total) * 100),
          }));
        }).send((err, data) => {
          if (err) {
            //console.log(err);
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      });
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Error uploading files:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmissionStatus(null);

    try {
      await uploadFiles(attachments);

      const formData = {
        title,
        dateOfRequest,
        requesterName,
        requesterEmail,
        productType,
        productName,  
        description,
        input,
        output,
        workflow,
        integration,
        completionDate,
        comments,
        attachments: attachments.map(file => file.name)
      };

      const response = await fetch('https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/feature-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_X_API_KEY
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setSubmissionStatus('success');
        navigate('/app/feature-request/success');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error submitting form');
      }
    } catch (error) {
      setSubmissionStatus('error');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const toTitleCase = (str) => {
    return str.replace(/([A-Z])/g, ' $1')
              .replace(/^./, function (str) { return str.toUpperCase(); })
              .trim();
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <form onSubmit={handleSubmit} className="p-4 space-y-4">
      <h3 className="text-xl font-bold mb-4">General Information</h3>
      {submissionStatus === 'success' && <p className="text-green-500">Form submitted successfully!</p>}
      {submissionStatus === 'error' && <p className="text-red-500">Error submitting form. Please try again.</p>}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Request Title:</label>
        <input 
          type="text" 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
          required 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Date of Request:</label>
        <input 
          type="text" 
          value={dateOfRequest} 
          disabled 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 cursor-not-allowed"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Requester's Name:</label>
        <input 
          type="text" 
          value={requesterName} 
          disabled 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 cursor-not-allowed"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Requester's Email:</label>
        <input 
          type="email" 
          value={requesterEmail} 
          disabled 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 cursor-not-allowed"
        />
      </div>
      <h3 className="text-xl font-bold mb-4">Feature Details</h3>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Is this request for an existing product or a new product?</label>
        <div className="flex justify-center space-x-4">
          <label className="inline-flex items-center">
            <input 
              type="radio" 
              name="productType" 
              value="existing" 
              checked={productType === 'existing'} 
              onChange={(e) => setProductType(e.target.value)} 
              className="form-radio"
            />
            <span className="ml-2">Existing Product</span>
          </label>
          <label className="inline-flex items-center">
            <input 
              type="radio" 
              name="productType" 
              value="new" 
              checked={productType === 'new'} 
              onChange={(e) => setProductType(e.target.value)} 
              className="form-radio"
            />
            <span className="ml-2">New Product</span>
          </label>
        </div>
      </div>
      {productType === 'existing' && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Product Name:</label>
          <select 
            value={productName} 
            onChange={(e) => setProductName(e.target.value)} 
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">Select a product</option>
            {validProductNames.map((name) => (
              <option key={name} value={name}>{name}</option>
            ))}
          </select>
        </div>
      )}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Detailed Description:</label>
        <textarea 
          value={description} 
          onChange={(e) => setDescription(e.target.value)} 
          onFocus={() => setShowDescriptionCount(true)}
          onBlur={() => setShowDescriptionCount(false)}
          maxLength={MAX_LENGTH}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
        />
        {showDescriptionCount && (
          <div className="text-right text-sm text-gray-500">{description.length}/{MAX_LENGTH}</div>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Expected Input:</label>
        <textarea 
          value={input} 
          onChange={(e) => setInput(e.target.value)} 
          onFocus={() => setShowInputCount(true)}
          onBlur={() => setShowInputCount(false)}
          maxLength={MAX_LENGTH}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
        />
        {showInputCount && (
          <div className="text-right text-sm text-gray-500">{input.length}/{MAX_LENGTH}</div>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Expected Output:</label>
        <textarea 
          value={output} 
          onChange={(e) => setOutput(e.target.value)} 
          onFocus={() => setShowOutputCount(true)}
          onBlur={() => setShowOutputCount(false)}
          maxLength={MAX_LENGTH}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
        />
        {showOutputCount && (
          <div className="text-right text-sm text-gray-500">{output.length}/{MAX_LENGTH}</div>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Workflow Description:</label>
        <textarea 
          value={workflow} 
          onChange={(e) => setWorkflow(e.target.value)} 
          onFocus={() => setShowWorkflowCount(true)}
          onBlur={() => setShowWorkflowCount(false)}
          maxLength={MAX_LENGTH}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
        />
        {showWorkflowCount && (
          <div className="text-right text-sm text-gray-500">{workflow.length}/{MAX_LENGTH}</div>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Integration Points:</label>
        <textarea 
          value={integration} 
          onChange={(e) => setIntegration(e.target.value)} 
          onFocus={() => setShowIntegrationCount(true)}
          onBlur={() => setShowIntegrationCount(false)}
          maxLength={MAX_LENGTH}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
        />
        {showIntegrationCount && (
          <div className="text-right text-sm text-gray-500">{integration.length}/{MAX_LENGTH}</div>
        )}
      </div>
      <h3 className="text-xl font-bold mb-4">Attachments</h3>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Attachments:</label>
        <input 
          type="file"
          multiple
          onChange={handleFileChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <div className="mt-4">
          {attachments.map(file => (
            <div key={file.name} className="mb-2">
              <div className="flex justify-between items-center">
                <span className="text-sm">{file.name}</span>
                <span className="text-sm">{progress[file.name]}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${progress[file.name]}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <h3 className="text-xl font-bold mb-4">Additional Information</h3>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Desired Completion Date:</label>
        <input 
          type="date" 
          value={completionDate} 
          onChange={(e) => setCompletionDate(e.target.value)} 
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">Additional Comments or Notes:</label>
        <textarea 
          value={comments} 
          onChange={(e) => setComments(e.target.value)} 
          onFocus={() => setShowCommentsCount(true)}
          onBlur={() => setShowCommentsCount(false)}
          maxLength={MAX_LENGTH}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
        />
        {showCommentsCount && (
          <div className="text-right text-sm text-gray-500">{comments.length}/{MAX_LENGTH}</div>
        )}
      </div>
      <div className="mb-4">
        <button 
          type="submit" 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={loading}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default FeatureRequestForm;
