import React from 'react';
import RenderMenu from './RenderMenu';
function ProductsPage() {
    return (
        <>
        <RenderMenu />
        <div className="bg-gray-100 min-h-screen p-8">
            <h1 className="text-3xl font-bold text-center mb-6">Our Products</h1>
            <div className="grid md:grid-cols-3 gap-4">
                {/* Product 1: Document Assembly */}
                <div className="bg-white p-4 shadow rounded-lg">
                    <h2 className="text-xl font-bold text-blue-600">Document Assembly</h2>
                    <p className="mt-2">
                        Our Document Assembly product takes data from various locations and creates fully fledged documents,
                        simplifying the document creation process and ensuring accuracy and compliance.
                    </p>
                </div>

                {/* Product 2: Process Automation */}
                <div className="bg-white p-4 shadow rounded-lg">
                    <h2 className="text-xl font-bold text-blue-600">Process Automation</h2>
                    <p className="mt-2">
                        Our Process Automation service takes data from internal sources and produces value-added outputs.
                        It streamlines operations, reduces manual effort, and enhances overall efficiency.
                    </p>
                </div>

                {/* Product 3: Speech Analytics */}
                <div className="bg-white p-4 shadow rounded-lg">
                    <h2 className="text-xl font-bold text-blue-600">Speech Analytics</h2>
                    <p className="mt-2">
                        Our Speech Analytics tool takes call recordings and makes them easy to manage by answering compliance
                        and quality questions, offering invaluable insights into your customer interactions.
                    </p>
                </div>
            </div>
        </div>
        </>
    );
}

export default ProductsPage;
