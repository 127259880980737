import React from 'react';
import RenderMenu from './RenderMenu';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
  const navigate = useNavigate();
  return (
    <>
      <RenderMenu />
      <div className="landing-page bg-gradient-to-r from-blue-500 to-indigo-800 min-h-screen flex flex-col items-center justify-center text-white p-4">
        <h1 className="text-4xl font-bold mb-2">Welcome to Everlumen</h1>
        <p className="text-xl mb-4">Please login to access the customer dashboard.</p>
        <div className="flex space-x-4">
          <button className="bg-white text-gray-800 font-bold py-2 px-4 rounded hover:bg-gray-100 transition duration-300"
          onClick={() => navigate('/app')}>
            Start now
          </button>
          <button className="bg-transparent border-2 border-white py-2 px-4 rounded hover:bg-white hover:text-gray-800 transition duration-300"
            onClick={() => navigate('/contact')}>
            Contact sales
          </button>

        </div>
        <div className="mt-8 text-center">
          <h2 className="text-2xl font-semibold">Why Everlumen?</h2>
          <p className="mt-4 text-lg">Discover how we can help you grow your business with our tailored solutions.</p>
          <div className="flex mt-4 space-x-4">
            <div className="p-4 bg-white rounded-lg shadow-lg text-gray-800">
              <h3 className="font-bold">Process Automation</h3>
              <p>Automate your workflow.</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow-lg text-gray-800">
              <h3 className="font-bold">Document Assembly</h3>
              <p>Create and work with documents.</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow-lg text-gray-800">
              <h3 className="font-bold">Speech Analytics</h3>
              <p>Call center compliance and automation.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
