import React from 'react';

const FeatureRequestLoading = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-2xl font-bold mb-4">Submitting Your Feature Request...</h1>
      <p>Please wait while we process your request.</p>
      <div className="loader mt-4"></div> {/* You can replace this with a spinner or any other loading indicator */}
    </div>
  );
};

export default FeatureRequestLoading;