import React from 'react';
import RenderMenu from './RenderMenu';
function SolutionsPage() {
    return (
        <>
        <RenderMenu />
        <div className="bg-gray-100 min-h-screen p-8">
            <h1 className="text-3xl font-bold text-center mb-6">Our Solutions</h1>
            <div className="grid md:grid-cols-3 gap-4">
{/* Solution 1: On Premise to Cloud Integration */}
<div className="bg-white p-4 shadow rounded-lg">
    <h2 className="text-xl font-bold text-green-600">On Premise to Cloud Integration</h2>
    <p className="mt-2">
        Our integration solution can process data either on premise or in the cloud, with on premise software maintaining synchronization between the two environments. This flexible approach allows for near real-time data processing and seamless data flow on your network.
    </p>
</div>


                {/* Solution 2: Third-Party Database Utilization */}
                <div className="bg-white p-4 shadow rounded-lg">
                    <h2 className="text-xl font-bold text-green-600">Third-Party Database Utilization</h2>
                    <p className="mt-2">
                        We integrate third-party databases to add and enrich information within records. This solution
                        provides enhanced insights and aids in making informed decisions by accessing a wider range of data sources.
                    </p>
                </div>

                {/* Solution 3: Custom Workflow Development */}
                <div className="bg-white p-4 shadow rounded-lg">
                    <h2 className="text-xl font-bold text-green-600">Custom Workflow Development</h2>
                    <p className="mt-2">
                        Our team specializes in developing custom workflows tailored to specific business needs.
                        These workflows facilitate efficiency and are designed to optimize various operational aspects
                        within your organization.
                    </p>
                </div>

                {/* Solution 4: Utilization of LLMs for Workflow Queries */}
                <div className="bg-white p-4 shadow rounded-lg">
                    <h2 className="text-xl font-bold text-green-600">LLMs for Workflow Queries</h2>
                    <p className="mt-2">
                        We utilize advanced Language Learning Models (LLMs) to provide real-time answers about workflows,
                        enabling businesses to handle queries efficiently and improve decision-making processes.
                    </p>
                </div>
            </div>
        </div>
        </>
    );
}

export default SolutionsPage;
