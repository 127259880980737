import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCaseDetails, updateCaseStatus, addComment, fetchSignedUrls, fetchComments } from './supportService';
import { getSignedUrlForObject } from '../aws-config'; // Correct import
import CommentsList from './CommentsList';

const SupportCaseDetails = ({ user, userData }) => {
  const { caseId } = useParams();
  const [caseDetails, setCaseDetails] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [newComment, setNewComment] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  useEffect(() => {
    const loadCaseDetails = async () => {
      try {
        const details = await fetchCaseDetails(caseId);
        console.log('Fetched case details:', details);
        setCaseDetails(details);
        setNewStatus(details.Status);

        // Fetch signed URLs for attachments
        if (details.Attachments && details.Attachments.length > 0) {
          const signedUrls = await fetchSignedUrls(details.Attachments);
          console.log('Fetched signed URLs:', signedUrls);
          setAttachments(signedUrls);
        }

        // Fetch comments for the case
        const commentsData = await fetchComments(caseId);
        console.log('Fetched comments:', commentsData);
        setComments(commentsData);
      } catch (error) {
        console.error('Error loading case details:', error);
      }
    };

    loadCaseDetails();
  }, [caseId]);

  const handleStatusUpdate = async () => {
    try {
      await updateCaseStatus(caseId, newStatus);
      const updatedDetails = await fetchCaseDetails(caseId);
      setCaseDetails(updatedDetails);
      setNewStatus(updatedDetails.Status);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleAddComment = async () => {
    if (newComment.trim()) {
      try {
        await addComment(caseId, newComment, userData.Name);
        setNewComment('');
        const updatedDetails = await fetchCaseDetails(caseId);
        setCaseDetails(updatedDetails);
        // Fetch updated signed URLs for attachments
        if (updatedDetails.Attachments && updatedDetails.Attachments.length > 0) {
          const signedUrls = await fetchSignedUrls(updatedDetails.Attachments);
          setAttachments(signedUrls);
        }
        // Fetch updated comments
        const updatedComments = await fetchComments(caseId);
        setComments(updatedComments);
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);

    // Initialize progress state to 0% for each file
    const initialProgress = files.reduce((acc, file) => {
      acc[file.name] = 0;
      return acc;
    }, {});
    setProgress(initialProgress);
  };

  const handleUploadFiles = async () => {
    if (selectedFiles.length === 0 || !caseDetails) {
      return;
    }

    setUploading(true);
    let filesUploaded = 0;
    const fileList = Array.from(selectedFiles);
    const casePK = caseDetails.PK; // Use the PK value from case details

    try {
      for (const file of fileList) {
        try {
          const uploadUrl = await getSignedUrlForObject(`${casePK}/${file.name}`);
          console.log('Signed URL for file:', file.name, uploadUrl);
          await uploadFile(file, uploadUrl);
          filesUploaded += 1;

          // Show success message if all files are uploaded
          if (filesUploaded === fileList.length) {
            setUploadSuccess(true);
            // Fetch signed URLs after uploading files
            const updatedDetails = await fetchCaseDetails(caseId);
            if (updatedDetails.Attachments && updatedDetails.Attachments.length > 0) {
              const signedUrls = await fetchSignedUrls(updatedDetails.Attachments);
              setAttachments(signedUrls);
            }
          }
        } catch (error) {
          console.error('Error uploading file:', file.name, error);
        }
      }
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
    }
  };

  const uploadFile = (file, url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setProgress(prevProgress => ({
            ...prevProgress,
            [file.name]: percentCompleted,
          }));
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject(new Error(`Upload failed: ${xhr.statusText}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Upload error'));
      };

      xhr.send(file);
    });
  };

  if (!caseDetails) {
    return <p>Loading...</p>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg text-left">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Case Details</h2>
      <div className="border p-4 rounded-md mb-4 text-left flex justify-between">
        <div>
          <p className="mb-2"><strong>Title:</strong> {caseDetails.Title}</p>
          <p className="mb-2"><strong>Status:</strong> {caseDetails.Status}</p>
          <p className="mb-2"><strong>Description:</strong> {caseDetails.Description}</p>
          <p className="mb-2"><strong>Created:</strong> {new Date(caseDetails.CreateDate * 1000).toLocaleString()}</p>
          <p className="mb-2"><strong>Last Updated:</strong> {new Date(caseDetails.LastUpdatedDate * 1000).toLocaleString()}</p>
        </div>
        <div className="ml-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Update Status</label>
          <select
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md mb-2"
          >
            <option value="New">New</option>
            <option value="In Progress">In Progress</option>
            <option value="Resolved">Resolved</option>
          </select>
          <button
            onClick={handleStatusUpdate}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Update Status
          </button>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-bold">Attachments</h3>
        {attachments && attachments.length > 0 ? (
          <ul className="list-disc ml-5">
            {attachments.map((attachment) => (
              <li key={attachment.file_name}>
                <a href={attachment.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {attachment.file_name}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <p>No attachments</p>
        )}
      </div>
      <div className="mt-4">
        <input type="file" multiple onChange={handleFileChange} />
        <button
          onClick={handleUploadFiles}
          className="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={uploading}
        >
          Upload Files
        </button>
        <div className="mt-4">
          {Array.from(selectedFiles).map(file => (
            <div key={file.name} className="mb-2">
              <div className="flex justify-between items-center">
                <span className="text-sm">{file.name}</span>
                <span className="text-sm">{progress[file.name] || 0}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${progress[file.name] || 0}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
        {uploadSuccess && (
          <div className="mt-4 text-green-600 font-bold">
            All files have been uploaded successfully!
          </div>
        )}
      </div>
      <CommentsList comments={comments} />
      <div className="border p-4 rounded-md mb-4 text-left">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="w-full p-2 border rounded-md mt-4"
          placeholder="Add a comment"
        />
        <button
          onClick={handleAddComment}
          className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Submit Comment
        </button>
      </div>
    </div>
  );
};

export default SupportCaseDetails;
