import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const FeatureRequestDetails = ({ userData }) => {
  const { requestId } = useParams();
  const [featureRequest, setFeatureRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [newComment, setNewComment] = useState('');

  const fetchFeatureRequestDetails = async () => {
    try {
      const response = await fetch(
        `https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/feature-request?email=${userData.Email}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        const parsedData = JSON.parse(result.body || '{}');

        const requestData = parsedData.items.find(item => item.SK === requestId);

        if (requestData) {
          setFeatureRequest(requestData);
          setStatus(requestData.Status || '');
        } else {
          console.error('No matching feature request found');
        }
      } else {
        console.error('Failed to fetch feature request details');
      }
    } catch (error) {
      console.error('Error fetching feature request details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeatureRequestDetails();
  }, [requestId, userData.Email]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleNewCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSave = async () => {
    try {
      const updatedData = {};

      if (status !== featureRequest.Status) {
        updatedData.status = status;
      }

      if (newComment) {
        updatedData.additionalInfo = featureRequest.additionalInfo 
          ? `${featureRequest.additionalInfo}\n${newComment}` 
          : newComment;
      } else {
        updatedData.additionalInfo = featureRequest.additionalInfo; 
      }

      const response = await fetch(
        `https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/feature-request/${requestId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.ok) {
        setNewComment('');
        setFeatureRequest((prevRequest) => ({
          ...prevRequest,
          Status: updatedData.status || prevRequest.Status,
          additionalInfo: updatedData.additionalInfo,
        }));
      } else {
        console.error('Failed to update status and/or new comment');
      }
    } catch (error) {
      console.error('Error updating status and/or new comment:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!featureRequest) {
    return <p>No details found for this feature request.</p>;
  }

  return (
    <div className="p-4 space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-center flex-1">Feature Request Details</h3>
        <div className="text-right">
          <strong>Status:</strong>
          <select 
            value={status} 
            onChange={handleStatusChange} 
            className="ml-2 bg-white border border-gray-300 rounded px-2 py-1"
          >
            <option value="New">New</option>
            <option value="In progress">In Progress</option>
            <option value="Completed">Completed</option>
          </select>
        </div>
      </div>
      <div className="text-left space-y-2">
        <p><strong>Title:</strong> {featureRequest.Name || 'N/A'}</p>
        <p><strong>Product:</strong> {featureRequest.ProductName || 'N/A'}</p>
        <p><strong>Description:</strong> {featureRequest.Description || 'N/A'}</p>
        <p><strong>Date Submitted:</strong> {new Date(featureRequest.CreateDate * 1000).toLocaleDateString() || 'N/A'}</p>
        <p><strong>Expected Input:</strong> {featureRequest.Input || 'N/A'}</p>
        <p><strong>Expected Output:</strong> {featureRequest.Output || 'N/A'}</p>
        <p><strong>Workflow:</strong> {featureRequest.Workflow || 'N/A'}</p>
        <p><strong>Integration Points:</strong> {featureRequest.Integration || 'N/A'}</p>
        <div>
          <strong>Additional Information:</strong>
          <div>{featureRequest.additionalInfo ? featureRequest.additionalInfo.split('\n').map((line, index) => (
            <div key={index}>{line}</div>
          )) : 'No additional information yet.'}</div>
        </div>
        <div>
          <textarea
            value={newComment}
            onChange={handleNewCommentChange}
            className="w-full mt-2 p-2 border border-gray-300 rounded"
            rows="4"
            placeholder="Add your comment here..."
          />
        </div>
        <button
          onClick={handleSave}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Save Changes
        </button>
        {featureRequest.Attachments && featureRequest.Attachments.length > 0 && (
          <div>
            <strong>Attachments:</strong>
            <ul>
              {featureRequest.Attachments.map((attachment, index) => (
                <li key={index}><a href={attachment.url} target="_blank" rel="noopener noreferrer">{attachment.name}</a></li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureRequestDetails;
