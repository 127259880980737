import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import GetAccessRights from './GetAccessRights';
import RenderMenu from './RenderMenu';
import SupportRequestForm from './support/SupportRequestForm';
import SupportRequestList from './support/SupportRequestList';
import SupportRequestSuccess from './support/SupportRequestSuccess';
import LandingPage from './LandingPage';
import ProductsPage from './ProductsPage';
import SolutionsPage from './SolutionsPage';
import SalesContactForm from './SalesContactForm';
import SalesFormSuccess from './SalesFormSuccess';
import Pricing from './Pricing';
import Developers from './Developers';
import FileUploadForm from './FileUploadForm';
import UploadSuccess from './UploadSuccess';
import SupportCaseDetails from './support/SupportCaseDetails';
import Settings from './Settings';
import FeatureRequestForm from './feature-request/FeatureRequestForm';
import FeatureRequestSuccess from './feature-request/FeatureRequestSuccess';
import FeatureRequestList from './feature-request/FeatureRequestList';
import FeatureRequestLoading from './feature-request/FeatureRequestLoading';
import FeatureRequestDecision from './feature-request/FeatureRequestDecision';
import FeatureRequestDetails from './feature-request/FeatureRequestDetails';
import './App.css';
import './output.css';
import 'tailwindcss/tailwind.css';
import '@aws-amplify/ui-react/styles.css';

function App() {
  const [userData, setUserData] = useState(null);

  const handleUserDataFetched = (data) => {
    setUserData(data);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/solutions" element={<SolutionsPage />} />
        <Route path="/contact" element={<SalesContactForm />} />
        <Route path="/sales-form-success" element={<SalesFormSuccess />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/developers" element={<Developers />} />
        <Route path="/uploader" element={<FileUploadForm />} />
        <Route path="/upload-success" element={<UploadSuccess />} />
        <Route
          path="/app/*"
          element={
            <Authenticator>
              {({ signOut, user }) => (
                <div className="App">
                  {userData ? (
                    <>
                      <RenderMenu accessRights={userData.AccessRights} user={userData} signOut={signOut} />
                      <Routes>
                        <Route path="/" element={
                          <header className="App-header">
                            <p>Customer Dashboard</p>
                          </header>
                        } />
                        <Route path="support" element={<SupportRequestList user={user} userData={userData} />} />
                        <Route path="support/newcase" element={<SupportRequestForm user={user} userData={userData} />} />
                        <Route path="support/success" element={<SupportRequestSuccess />} />
                        <Route path="support/case/:caseId" element={<SupportCaseDetails user={user} userData={userData}/>} />
                        <Route path="settings" element={<Settings accessRights={userData.AccessRights} user={userData} signOut={signOut}/>} />
                        <Route path="feature-requests" element={<FeatureRequestList userData={userData} />} />
                        <Route path="feature-request" element={<FeatureRequestDecision userData={userData} />} />
                        <Route path="feature-request/new" element={<FeatureRequestForm user={user} userData={userData} />} />
                        <Route path="feature-request/:requestId" element={<FeatureRequestDetails user={user} userData={userData} />} />
                        <Route path="feature-request/loading" element={<FeatureRequestLoading />} />
                        <Route path="feature-request/success" element={<FeatureRequestSuccess />} />
                      </Routes>
                    </>
                  ) : (
                    <GetAccessRights user={user} onUserDataFetched={handleUserDataFetched} />
                  )}
                </div>
              )}
            </Authenticator>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
