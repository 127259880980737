import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSupportRequest } from './supportService';
import { getSignedUrlForObject } from '../aws-config';
import '../App.css';
import '../output.css';
import 'tailwindcss/tailwind.css';
import '@aws-amplify/ui-react/styles.css';
import { v4 as uuidv4 } from 'uuid';

function SupportRequestForm({ user, userData }) {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [progress, setProgress] = useState({});
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(user);

    // Generate a new PK for the support case
    const casePK = uuidv4();

    // Prepare request data without attachment URLs
    const requestData = {
      PK: casePK,
      SK: userData.SK,
      Title: title,
      Description: description,
      Reporter: userData.Name,
      Email: userData.Email,
      Phone: userData.Phone,
      Attachments: attachments.map(file => `${casePK}/${file.name}`),
    };

    try {
      if (attachments.length > 0) {
        setUploading(true);
        await uploadFiles(attachments, casePK);
        setUploading(false);
      }

      // Create support request
      const response = await createSupportRequest(requestData);
      console.log('Support request created successfully:', response);
      setTitle('');
      setDescription('');
      setAttachments([]);
      setProgress({});

      // Navigate to a success page or display a success message
      navigate('/app/support');
    } catch (error) {
      console.error('Failed to create support request:', error);
      setUploading(false);
    }
  };

  const handleAttachmentChange = (event) => {
    const files = Array.from(event.target.files);
    setAttachments(files);
  };

  const uploadFiles = async (files, casePK) => {
    const uploadPromises = files.map(async file => {
      const signedUrl = await getSignedUrlForObject(`${casePK}/${file.name}`, 'putObject');
      return uploadFile(file, signedUrl);
    });
    await Promise.all(uploadPromises);
  };

  const uploadFile = (file, url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setProgress(prevProgress => ({
            ...prevProgress,
            [file.name]: percentCompleted,
          }));
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject(new Error(`Upload failed: ${xhr.statusText}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Upload error'));
      };

      xhr.send(file);
    });
  };

  return (
    <div className="bg-customDarkSlateGray min-h-screen">
      <div className="max-w-lg mx-auto pt-8 pb-16 px-4">
        <h2 className="text-2xl font-bold mb-4 text-white">Create Support Request</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-white">
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title of your issue"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-white text-gray-900"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-white">
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description of your issue or request"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-white text-gray-900"
              rows="4"
            />
          </div>
          <div>
            <label htmlFor="attachments" className="block text-sm font-medium text-white">
              Attachments (Optional)
            </label>
            <input
              type="file"
              id="attachments"
              multiple
              onChange={handleAttachmentChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-white text-gray-900"
            />
            {uploading && (
              <div className="mt-2">
                {attachments.map(file => (
                  <div key={file.name} className="mb-2">
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-white">{file.name}</span>
                      <span className="text-sm text-white">{progress[file.name] || 0}%</span>
                    </div>
                    <div className="bg-gray-200 rounded-full h-2.5">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${progress[file.name] || 0}%` }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={uploading}
          >
            Submit Request
          </button>
        </form>
      </div>
    </div>
  );
}

export default SupportRequestForm;