import React from 'react';
import RenderMenu from './RenderMenu';

const Developers = () => {
  return (
    <>
      <RenderMenu />
      <div className="bg-gradient-to-r from-purple-500 to-pink-500 min-h-screen flex flex-col items-center text-white p-4">
        <h1 className="text-4xl font-bold mb-4">Developer Resources</h1>
        <p className="text-xl mb-6">Access detailed documentation, comprehensive guides, and all the tools you need to integrate with Everlumen's services.</p>
        
        <div className="w-full max-w-4xl">
          <h2 className="text-3xl font-bold mb-3">API Documentation</h2>
          <p className="text-lg mb-4">Our complete OpenAPI specification is available to explore. Find detailed information about all endpoints and services.</p>
          <a href="/api-docs" className="bg-white text-purple-800 font-bold py-2 px-4 rounded hover:bg-gray-100 transition duration-300 mb-4 inline-block">
            View API Documentation
          </a>

          <h2 className="text-3xl font-bold mb-3">Quick Start Guide</h2>
          <p className="text-lg mb-4">Get up and running with our APIs quickly using our step-by-step guide to integrating Everlumen's services.</p>
          <div className="bg-white text-gray-800 p-4 rounded-lg shadow-lg">
            <code className="block bg-gray-100 p-3 rounded mb-2">
              {`/* Example API call to fetch data */
              fetch('https://api.everlumen.com/v1/data', {
                headers: { 'Authorization': 'Bearer your-access-token' }
              }).then(response => response.json())
                .then(data => console.log(data));`}
            </code>
            <button className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded transition duration-300">
              Try it out
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Developers;
