import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../App.css';
import '../output.css';
import 'tailwindcss/tailwind.css';
import '@aws-amplify/ui-react/styles.css';

function SupportRequestList({ userData }) {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [statusFilter, setStatusFilter] = useState('All');
  const [sortOption, setSortOption] = useState('CaseId');
  const navigate = useNavigate();

  const fetchSupportRequests = useCallback(async () => {
    const url = 'https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/support/requests/list';
    const payload = {
      SK: userData.SK,
      Admin: userData.isAdmin,
      Reporter: userData.Name,
    };

    console.log('Payload:', JSON.stringify(payload));
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_X_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const parsedRequests = JSON.parse(data.body);
      setRequests(parsedRequests);
      setFilteredRequests(parsedRequests);

      if (parsedRequests.length === 0) {
        navigate('/app/support/newcase');
      }
    } catch (error) {
      console.error('Error fetching support requests:', error);
    }
  }, [userData, navigate]);

  useEffect(() => {
    fetchSupportRequests();
  }, [fetchSupportRequests]);

  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const filtered = useMemo(() => {
    let result = requests;
    if (statusFilter !== 'All') {
      result = requests.filter((request) => request.Status === statusFilter);
    }
    return result;
  }, [statusFilter, requests]);

  const sorted = useMemo(() => {
    return [...filtered].sort((a, b) => {
      if (sortOption === 'CreateDate' || sortOption === 'LastUpdatedDate') {
        return new Date(a[sortOption]) - new Date(b[sortOption]);
      } else {
        return a[sortOption] > b[sortOption] ? 1 : -1;
      }
    });
  }, [sortOption, filtered]);

  useEffect(() => {
    setFilteredRequests(sorted);
  }, [sorted]);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Support Requests</h2>

      <div className="flex mb-4">
        <select
          value={statusFilter}
          onChange={handleFilterChange}
          className="mr-4 p-2 border rounded"
        >
          <option value="All">All</option>
          <option value="New">New</option>
          <option value="In Progress">In Progress</option>
          <option value="Resolved">Resolved</option>
        </select>

        <select
          value={sortOption}
          onChange={handleSortChange}
          className="p-2 border rounded"
        >
          <option value="CaseId">Case ID</option>
          <option value="Title">Title</option>
          <option value="Status">Status</option>
          <option value="CreateDate">Created</option>
          <option value="LastUpdatedDate">Last Updated</option>
        </select>
      </div>

      {filteredRequests.length > 0 ? (
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Case ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Updated</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredRequests.map((request) => (
              <tr key={request.CaseId}>
                <td className="px-6 py-4 text-left whitespace-nowrap">
                  <Link to={`case/${request.CaseId}`} className="text-indigo-600 hover:text-indigo-900">
                    {request.CaseId}
                  </Link>
                </td>
                <td className="px-6 py-4 text-left whitespace-nowrap">{request.Title}</td>
                <td className="px-6 py-4 text-left whitespace-nowrap">{request.Status}</td>
                <td className="px-6 py-4 text-left whitespace-nowrap">{request.Description}</td>
                <td className="px-6 py-4 text-left whitespace-nowrap">{new Date(parseInt(request.CreateDate) * 1000).toLocaleString()}</td>
                <td className="px-6 py-4 text-left whitespace-nowrap">{new Date(parseInt(request.LastUpdatedDate) * 1000).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="mt-5 text-lg leading-6 font-medium text-gray-900">No support requests found.</p>
      )}

      <div className="mt-4 flex justify-start pl-4">
        <button
          onClick={() => navigate('/app/support/newcase')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Create New Case
        </button>
      </div>
    </div>
  );
}

export default SupportRequestList;
