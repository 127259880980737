import React from 'react';
import RenderMenu from './RenderMenu';

const Pricing = () => {
  return (
    <>
      <RenderMenu />
      <div className="bg-gradient-to-r from-blue-500 to-indigo-800 min-h-screen flex flex-col items-center justify-center text-white p-4">       
        <div className="w-full max-w-4xl p-5">
        <h2 className="text-3xl font-bold mb-3">Pricing</h2>
          <p className="text-lg mb-5">Transparent billing tailored to your needs. Pay only for what you use.</p>
          <h2 className="text-3xl font-bold mb-3">Understand Our Pricing</h2>
          <p className="text-lg mb-5">Our transparent billing model allows you to track expenses clearly, understand the full value of the services you're receiving, and avoid the hassle of comparing costs as your needs evolve.</p>
          <div className="flex flex-wrap justify-center gap-4">
            <div className="p-4 bg-white text-gray-800 rounded-lg shadow-lg w-64">
              <h3 className="font-bold text-2xl mb-2">Basic Package</h3>
              <p>Perfect for startups looking to scale. Includes basic features with pay-as-you-go pricing.</p>
            </div>
            <div className="p-4 bg-white text-gray-800 rounded-lg shadow-lg w-64">
              <h3 className="font-bold text-2xl mb-2">Enterprise Package</h3>
              <p>Comprehensive solutions for large organizations, featuring advanced tools and dedicated support.</p>
            </div>
          </div>
          <div className="mt-8 text-center">
            <button className="bg-white text-gray-800 font-bold py-2 px-4 rounded hover:bg-gray-100 transition duration-300"
            onClick={() => window.location.href = '/app'}>
              Get Started
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
