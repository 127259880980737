import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RenderMenu from './RenderMenu';


function SalesContactForm() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/contact', { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_X_API_KEY,
                },
                body: JSON.stringify({ email, country }),
            });

            if (response.ok) {
                navigate('/sales-form-success'); // Redirect to success page
            } else {
                alert('Failed to send email. Please try again!');
            }
        } catch (error) {
            console.error('Failed to send email:', error);
        }
    };

    return (
        <>
            <RenderMenu />
            <div className="flex flex-col md:flex-row items-center justify-center min-h-screen p-4 bg-gradient-to-r from-blue-500 to-indigo-800 text-white">
                <div className="w-full max-w-4xl mx-auto p-15">
                    <div className="flex justify-between items-start gap-10">
                        <div className="flex-1">
                            <h1 className="text-4xl font-bold mb-4">Tell us about your business</h1>
                            <p className="mb-6">
                                Leveraging Everlumen to enhance your workflow is easier than ever. Our sales and customer success team would be happy to guide you.
                            </p>
                        </div>
                        <div className="bg-white text-gray-800 p-8 rounded-lg shadow-lg max-w-md">
                            <h2 className="text-2xl font-semibold mb-4">Let's get you to the right place</h2>
                            <p>We just need a few quick details.</p>
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="email" className="block mt-4">
                                    Work email
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="alice@example.com"
                                        className="mt-1 p-2 w-full border rounded"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </label>
                                <label htmlFor="country" className="block mt-4">
                                    Country/Region
                                    <select
                                        id="country"
                                        className="mt-1 p-2 w-full border rounded"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        <option value="United States">United States</option>
                                        <option value="Canada">Canada</option>
                                        
                                    </select>
                                </label>
                                <button type="submit" className="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                    Continue
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalesContactForm;