import React from 'react';
import ReactDOM from 'react-dom/client';
import './tailwinds-output.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// AWS Amplify Configuration
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports'; // Ensure this path is correct
Amplify.configure(awsExports);

// Create the root element
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Performance reporting
reportWebVitals();
