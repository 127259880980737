import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import logo from './images/logo180.png';

function RenderMenu({ accessRights, user, signOut }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <nav className="bg-customDarkSlateGray text-white p-4 flex items-center justify-between">
      <div className="flex items-center">
        <img src={logo} alt="Everlumen Logo" className="h-11 w-auto mr-4" />
        <Link to="/" className="text-lg font-bold text-white mr-6">Everlumen</Link>
      </div>

      <div className="md:flex flex-col md:flex-row justify-center items-center absolute md:relative top-full w-full md:w-auto bg-customDarkSlateGray">
        <ul className="flex flex-col md:flex-row justify-center items-center">
          {accessRights && (
            <>
              {accessRights.includes('supportModule') && (
                <li className="mx-2 my-2 md:my-0 font-bold">
                  <Link to="/app/support" className="text-gray-300 hover:text-gray-100">Support</Link>
                </li>
              )}
              {accessRights.includes('callCompliance') && (
                <li className="mx-2 my-2 md:my-0 font-bold">
                  <a className="text-gray-300 hover:text-gray-100" href="home.html">Call Compliance</a>
                </li>
              )}
              {accessRights.includes('reportsModule') && (
                <li className="mx-2 my-2 md:my-0 font-bold">
                  <a className="text-gray-300 hover:text-gray-100" href="home.html">Reports</a>
                </li>
              )}
              {accessRights.includes('featureRequest') && (
                <li className="mx-2 my-2 md:my-0 font-bold">
                  <Link to="/app/feature-request" className="text-gray-300 hover:text-gray-100">Feature Request</Link>
                </li>
              )}
            </>
          )}
          {!accessRights && (
            <>
              <li className="mx-2 my-2 md:my-0 font-bold">
                <Link to="/products" className="text-gray-300 hover:text-gray-100">Products</Link>
              </li>
              <li className="mx-2 my-2 md:my-0 font-bold">
                <Link to="/solutions" className="text-gray-300 hover:text-gray-100">Solutions</Link>
              </li>
              <li className="mx-2 my-2 md:my-0 font-bold">
                <Link to="/developers" className="text-gray-300 hover:text-gray-100">Developers</Link>
              </li>
              <li className="mx-2 my-2 md:my-0 font-bold">
                <Link to="/pricing" className="text-gray-300 hover:text-gray-100">Pricing</Link>
              </li>
            </>
          )}
        </ul>
      </div>

      <div className="flex">
        {!accessRights && (
          <Link
            to="/app"
            className="bg-blue-500 text-white font-bold px-4 py-2 rounded-full sign-in-button"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            Sign in {isHovering ? '->' : '>'}
          </Link>
        )}
        {user && (
          <>
            <IconButton
              component={Link}
              to="/app/settings"
              color="inherit"
            >
              <SettingsIcon />
            </IconButton>
            <IconButton onClick={signOut} color="inherit">
              <ExitToAppIcon />
            </IconButton>
          </>
        )}
      </div>
    </nav>
  );
}

export default RenderMenu;
