import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FeatureRequestList = ({ userData }) => {
  const [featureRequests, setFeatureRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Mapping backend product names to display names
  const productNameMapping = {
    'featureRequest': 'Feature Request',
    'Support Module': 'Support Module',
    'Management Tools': 'Management Tools',
    'Call Compliance': 'Call Compliance',
    'Analytics Dashboard': 'Analytics Dashboard'
  };

  useEffect(() => {
    const fetchFeatureRequests = async () => {
      try {
        const response = await fetch(`https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/feature-request?email=${userData.Email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
          },
        });

        if (response.ok) {
          const result = await response.json();
          const parsedData = typeof result.body === 'string' ? JSON.parse(result.body || '{}') : result.body;

          if (parsedData.items && Array.isArray(parsedData.items)) {
            setFeatureRequests(parsedData.items);
          }
        }
      } catch (error) {
        console.error('Failed to fetch feature requests:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userData?.Email) {
      fetchFeatureRequests();
    }
  }, [userData]);

  const handleViewDetails = (featureRequest) => {
    navigate(`/app/feature-request/${featureRequest.SK}`, { state: { featureRequest } });
  };

  const handleNewRequest = () => {
    navigate('/app/feature-request/new');
  };

  const getProductName = (productName) => {
    return productNameMapping[productName.trim()] || 'N/A';
  };

  if (loading) {
    return <p>Loading feature requests...</p>;
  }

  return (
    <div className="p-4 space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-bold">Submitted Feature Requests</h3>
        <button 
          onClick={handleNewRequest} 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Submit New Request
        </button>
      </div>
      {featureRequests.length === 0 ? (
        <p>No feature requests submitted yet.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Title</th>
                <th className="px-4 py-2 border-b">Product</th>
                <th className="px-4 py-2 border-b">Date Submitted</th>
                <th className="px-4 py-2 border-b">Status</th>
              </tr>
            </thead>
            <tbody>
              {featureRequests.map((request, index) => (
                <tr 
                  key={index} 
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleViewDetails(request)}
                >
                  <td className="px-4 py-2 border-b">{request.Name || 'N/A'}</td>
                  <td className="px-4 py-2 border-b">{getProductName(request.ProductName)}</td>
                  <td className="px-4 py-2 border-b">{request.CreateDate ? new Date(request.CreateDate * 1000).toLocaleDateString() : 'N/A'}</td>
                  <td className="px-4 py-2 border-b">{request.Status || 'New'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FeatureRequestList;
