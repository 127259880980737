import { S3Client, ListObjectsCommand, GetObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const REGION = process.env.REACT_APP_AWS_REGION || 'us-east-2';
export const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || 'everlumen-support-attachments';

const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

export const listObjects = async (prefix) => {
  const command = new ListObjectsCommand({ 
    Bucket: S3_BUCKET,
    Prefix: prefix,
  });
  try {
    const data = await s3Client.send(command);
    return data.Contents || [];
  } catch (err) {
    console.error("Error", err);
    throw err;
  }
};

export const getSignedUrlForObject = async (key, operation = 'putObject') => {
  const command = operation === 'putObject'
    ? new PutObjectCommand({ Bucket: S3_BUCKET, Key: key })
    : new GetObjectCommand({ Bucket: S3_BUCKET, Key: key });

  try {
    const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
    return url;
  } catch (err) {
    console.error("Error", err);
    throw err;
  }
};