import React, { useState, useEffect } from 'react';

function Settings({ accessRights, user, signOut }) {
  const [formData, setFormData] = useState({
    name: user.Name,
    email: user.Email,
    phone: user.Phone,
    PK: user.PK,
    SK: user.SK,
    moduleName: '',
    moduleDescription: '',
    userDefault: ''
  });

  const [moduleDetails, setModuleDetails] = useState([]);

  useEffect(() => {
    const fetchModuleDetails = async () => {
      try {
        const url = 'https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/module/getinfo';
        
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
          },
          body: JSON.stringify({ accessRights }),
        });

        const result = await response.json();
        if (response.ok) {
          const parsedResult = JSON.parse(result.body); // Parse the body from JSON string
          setModuleDetails(parsedResult);
        } else {
          throw new Error(result.error || 'Error fetching module details');
        }
      } catch (error) {
        console.error('Error fetching module details:', error);
      }
    };

    if (accessRights && accessRights.length > 0) {
      fetchModuleDetails();
    }
  }, [accessRights]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = `https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/user/${formData.PK}/update`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_X_API_KEY,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Form submitted successfully:', result);
        // Handle success (e.g., show a success message)
      } else {
        throw new Error(result.error || 'Error submitting form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">User Settings</h1>
      <h2 className="text-xl font-semibold mb-2">User Details</h2>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4 flex items-center">
          <label className="block text-gray-700 text-sm font-bold mb-2 mr-4 w-24">
            Name:
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4 flex items-center">
          <label className="block text-gray-700 text-sm font-bold mb-2 mr-4 w-24">
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-6 flex items-center">
          <label className="block text-gray-700 text-sm font-bold mb-2 mr-4 w-24">
            Phone:
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
       
        <div className="mb-4 flex items-center">
          <label className="block text-gray-700 text-sm font-bold mb-2 mr-4 w-24">
            User Default:
          </label>
          <select
            name="userDefault"
            value={formData.userDefault}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">Select Default Module</option>
            {moduleDetails.map((detail, index) => (
              <option key={index} value={detail.SK}>
                {detail.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Save
          </button>
        </div>
      </form>
      <h2 className="text-xl font-semibold mb-2">Access Rights</h2>
      <ul className="list-disc list-inside bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {moduleDetails.length > 0 ? (
          moduleDetails.map((detail, index) => (
            <li key={index} className="text-gray-700 text-sm text-left">
              {detail.Name}: {detail.Description}
            </li>
          ))
        ) : (
          <li className="text-gray-700 text-sm">No access rights available.</li>
        )}
      </ul>
    </div>
  );
}

export default Settings;
