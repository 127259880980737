import { useEffect } from 'react';

function GetAccessRights({ user, onUserDataFetched }) {
  useEffect(() => {
    let isCancelled = false;

    const fetchUserData = (userId) => {
      const url = `https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/user/${userId}`;
      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_X_API_KEY,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (!isCancelled) {
          const parsedData = JSON.parse(data.body);
          if (!parsedData.AccessRights.includes('featureRequest')) {
            parsedData.AccessRights.push('featureRequest');
          }
          onUserDataFetched(parsedData);
        }
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
    };

    if (user && user.userId) {
      fetchUserData(user.userId);
    }

    return () => {
      isCancelled = true;
    };
  }, [user, onUserDataFetched]);

  return null;
}

export default GetAccessRights;
