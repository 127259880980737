import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FeatureRequestDecision = ({ userData }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkFeatureRequests = async () => {
      try {
        const response = await fetch(`https://4gxkfultn8.execute-api.us-east-2.amazonaws.com/v1/feature-request?email=${userData.Email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
          },
        });

        if (response.ok) {
          const result = await response.json();
          let items = [];

          if (result.body) {
            const bodyParsed = typeof result.body === 'string' ? JSON.parse(result.body) : result.body;
            items = bodyParsed.items || [];
          } else if (result.Items) {
            items = result.Items;
          }

          if (Array.isArray(items) && items.length > 0) {
            navigate('/app/feature-requests');
          } else {
            navigate('/app/feature-request/new');
          }
        } else {
          navigate('/app/feature-request/new');
        }
      } catch (error) {
        navigate('/app/feature-request/new');
      }
    };

    if (userData?.Email) {
      checkFeatureRequests();
    }
  }, [userData, navigate]);

  return null;
};

export default FeatureRequestDecision;
